/* Mobile layout deltas */

@include breakpoint-lte('sm') {
	
	body {
		overflow-x: hidden;
		
		&.no-scroll,
		&.mobile-menu-open {
			overflow: hidden;
		}
	}
	
}