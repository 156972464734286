.nominees-list {

	border-top: 1px solid $color-rule-grey;

	.nominee {
		.nominee-head {
			padding-top: 40px;

			.nominee-image {
				width: 140px;
				height: 140px;
				border-radius: 50%;
				border: 1px solid $color-rule-grey;
				margin-bottom: 24px;

				img {
					border-radius: 50%;
					border: 1px solid $color-rule-grey;
					height: 100%;
					width: 100%;
					object-fit: cover;
					object-position: center;
				}
			}

			h3 {
				font-size: em(21px);
				line-height: (30 / 21);
				margin-top: 0;
			}
		}

		.nominee-body {
			.nominee-award {
				color: $color-logo-green-teal;
				font-weight: bold;
				font-size: 18px;
			}

			.nominee-content {
				min-height: 280px !important;
				max-height: 280px !important;
				overflow-y: auto;
				padding-right: 25px;
			}

			.nominee-content::-webkit-scrollbar {
				-webkit-appearance: none;
				width: 7px;
			}

			.nominee-content::-webkit-scrollbar-thumb {
				border-radius: 4px;
				background-color: rgba(0, 0, 0, .2);
				-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
			}
		}

		.nominee-foot {
			padding-top: 40px;
			padding-bottom: 40px;
			text-align: center;

			.button-vote {
				margin: auto;
				margin-top: 12px;
			}

			.text-instruction {
				margin-left: 120px;
				line-height: 1.4;
			}

			.shortcode {
				text-transform: uppercase;
				@include font-avenir-heavy();
			}
		}

		&:nth-child(2n) {
			background: $color-bg-grey;
		}

	}

	@include breakpoint-lt('md') {
		.nominee {
			width: 50%;
		}
	}

	@include breakpoint-lt('sm') {
		.nominee {
			width: 100%;
		}
	}

}