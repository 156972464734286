/* CSS Document */

$padding-block-lg: 80px;
$width-block-lg: (
	$width-container-lg - $padding-block-lg) / 2;

$padding-block-md: 60px;
$width-block-md: (
	$width-container-md - $padding-block-md) / 2;

$padding-block-sm: 40px;
$width-block-sm: (
	$width-container-sm - $padding-block-sm) / 2;

.left-right-blocks {

	.block {
		@include clearfix(
	);

	&.image-right-text-left {
		.image {
			text-align: right;
		}
	}

	&.image-left-text-right {
		.content{
			text-align: right;
			@media (max-width: 767px){
				text-align: left;
			}
		}
	}

	.image {
		position: relative;
		width: $width-block-lg;
		float: right;

		img {
			max-width: 100%;
		}

		.caption {
			position: absolute;
			right: 0;
			bottom: em(-14px);
			font-size: em(14px);
			color: $color-text-green-lite;
			line-height: 1;
			font-style: italic;
		}
	}

	.content {
		margin-right: $width-block-lg + $padding-block-lg;
	}

	&:nth-last-of-type(2n) {
		.image {
			float: left;
		}

		.content {
			margin-right: 0;
			margin-left: $width-block-lg + $padding-block-lg;
		}
	}
}

@include breakpoint-lt('lg') {
	.block {
		.image {
			width: $width-block-md;
		}

		.content {
			margin-right: $width-block-md + $padding-block-md;
		}

		&:nth-last-of-type(2n) {
			.content {
				margin-right: 0;
				margin-left: $width-block-md + $padding-block-md;
			}
		}
	}
}

@include breakpoint-lt('md') {
	.block {
		.image {
			width: $width-block-sm;
		}

		.content {
			margin-right: $width-block-sm + $padding-block-sm;
		}

		&:nth-last-of-type(2n) {
			.content {
				margin-right: 0;
				margin-left: $width-block-sm + $padding-block-sm;
			}
		}
	}
}

@include breakpoint-lt('sm') {
	padding: 0;

	.block,
	.block:nth-last-of-type(2n) {
		.image {
			width: 100%;
			float: none;
			text-align: center;

			.caption {
				position: static;
				text-align: right;
				padding-right: 20px;
			}
		}

		.content {
			width: 100%;
			margin: 0;
			padding-left: $grid-padding-sm;
			padding-right: $grid-padding-sm;
			padding-bottom: $grid-padding-sm;
		}
	}
}

}