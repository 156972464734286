/* Colors */

/* Colors from logo */
$color-logo-yellow: #e3e87c;
$color-logo-green-bright: #0067B1;
$color-logo-green-mid: #0067B1;
$color-logo-green-mid2: #0067B1;
$color-logo-green-teal: #0067B1;
$color-logo-green-blue: #13b6d8;

$color-bcc-blue: rgb(0, 103, 177);
$color-bcc-yellow: rgb(255, 210, 0);
$color-bcc-green: rgb(0, 169, 79);

/* Text */
$color-text-body: #323232;
$color-text-link: $color-logo-green-teal;
$color-text-green: #0067B1;
$color-text-green-lite: #0067B1;

$color-text-header: #464648;
$color-text-header-hover: $color-logo-green-mid2;
$color-text-footer: #969696;

/* Form */
$color-form-bg: #0067B1;
$color-form-input: $color-text-header;
$color-form-placeholder: lighten($color-form-input, 30%);
$color-form-error: #e25757;

/* Rules */
$color-rule-grey: #e5e9e7;
$color-rule-green: #dce7f2;

/* Backgrounds */
$color-bg-grey: #fafafa;
$color-bg-green: #A7C7E7;

/* Gradients */
$gradient: linear-gradient(to right, $color-logo-green-bright 0%, $color-logo-green-teal 100%);
$gradient-transparent: linear-gradient(to right, rgba(darken($color-logo-green-bright, 20%), 0.3) 0%, rgba(darken($color-logo-green-teal, 20%), 0.3) 100%);

