/* CSS Document */

@mixin font-avenir-book() {
	font-family: 'Avenir LT W01_45 Book1475508', Arial, Helvetica, 'sans-serif';
	font-weight: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin font-avenir-medium() {
	font-family: 'Avenir LT W01_65 Medium1475532', Arial, Helvetica, 'sans-serif';
	font-weight: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin font-avenir-heavy() {
	font-family: 'Avenir LT W01_85 Heavy1475544', Arial, Helvetica, 'sans-serif';
	font-weight: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}