/* CSS Document */

.button {
	display: inline-block;
	@include font-avenir-heavy();
	color: #fff;
	background: $color-logo-green-mid2;
	line-height: 46px;
	padding: 0 27px;
	border-radius: 23px;
	text-decoration: none;
	transition: $ease-linear;
	
	&:hover {
		background: $color-logo-green-mid;
	}
}

button.button,
input.button {
	border: 0;
}

.button-white {
	color: $color-text-green-lite;
	background: #fff;
	&:hover {
		color: $color-logo-green-mid;
		background: #fff;
	}
}

.button-teal {
	background: $color-logo-green-teal;
	&:hover {
		background: darken($color-logo-green-teal, 10%);
	}
}