/* Grid */

.container {
	position: relative;
	margin: 0 auto;
	width: $width-container-lg;
	@include clearfix;
}


@include breakpoint-lt('lg') {
	.container {
		width: $width-container-md;
	}
}

@include breakpoint-lt('md') {
	.container {
		width: $width-container-sm;
	}
}



/*
 *	Row
 */

.row {
	position: relative;
	margin-left: -$grid-padding-lg;
	margin-right: -$grid-padding-lg;
	@include clearfix;
}


/*
 *	Spans
 */

.span-12,
.span-8,
.span-6,
.span-4 {
	float: left;
	padding-left: $grid-padding-lg;
	padding-right: $grid-padding-lg;
}

.span-12 {
	width: 100%;
}

.span-8 {
	width: 66.67%;
}

.span-6 {
	width: 50%;
}

.span-4 {
	width: 33.33%;
}


@include breakpoint-lt('sm') {
	.container {
		width: 100%;
	}
	.row {
		margin-left: 0;
		margin-right: 0;
	}
	.span-12,
	.span-8,
	.span-6,
	.span-4 {
		float: none;
		width: 100%;
		padding-left: $grid-padding-sm;
		padding-right: $grid-padding-sm;
	}
}