/* 
 * Nomination Form 
 * Style overrides for Gravity Form
 */

$height-form-fields: 51px;

.gform_wrapper.nomination-form_wrapper {
	
	.gform_heading {
		text-align: center;
		.gform_title {
			@include font-avenir-book();
			font-size: em(30px);
			line-height: (32 / 30);
			margin: 0;
		}
		.gform_description {
			@include font-avenir-medium();
			margin: 0;
		}
	}
	
	.gf_page_steps {
		border-bottom: 1px solid lighten($color-form-bg, 20%);
		text-align: center;
		
		.gf_step {
			position: relative;
			opacity: 0.4;
			margin: 0 0 24px 63px;
			
			&::before,
			&::after {
				content: '';
				position: absolute;
				display: block;
			}
			
			&::before {
				width: 40px;
				height: 1px;
				left: -53px;
				top: 14px;
				background: #fff;
			}
			&::after {
				width: 5px;
				height: 5px;
				left: -17px;
				top: 12px;
				border: 1px solid #fff;
				border-width: 1px 1px 0 0;
				transform: rotate(45deg);
			}
			
			.gf_step_number {
				width: 29px;
				height: 29px;
				border-radius: 50%;
				background: #fff;
				color: $color-form-bg;
				text-align: center;
				line-height: 29px;
				font-size: em(18px);
			}
			.gf_step_label {
				@include font-avenir-heavy();
				font-size: em(16px);
				line-height: 29px;
			}
		}
		.gf_step_active {
			opacity: 1;
			&::before,
			&::after {
				opacity: 0.4;
			}
		}
		.gf_step_first {
			margin: 0 0 24px 0;
			&::before,
			&::after {
				display: none;
			}
		}
		
		@include breakpoint-lt('sm') {
			text-align: left;
			.gf_step {
				margin-left: 20px;
				&::before,
				&::after {
					display: none;
				}
			}
		}
		
	}
	
	div.validation_error {
		display: none;
	}
	
	.gform_body {
		//width: 100% !important; 
	}
	
	.gfield {
		position: relative;
		margin-top: 16px 0 0 0;
    	padding-top: 0;
		padding-bottom: 0;
		
		.gfield_label {
			@include font-avenir-heavy();
			
			.gfield_required {
				display: none;
			}
		}
		
		.ginput_container {
			margin-top: 0;
		
			select,
			input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) {
				height: $height-form-fields;
			}
			
			select,
			input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']),
			textarea {
				color: $color-form-input;
				border: none;
				padding: (($height-form-fields - 26) / 2) 20px;
				font-size: em(18px);
				line-height: (26 / 18);
				border-radius: 4px;
				border: 2px solid #fff; 
				background: #fff;
				box-shadow: 0 0 0 rgba(0,0,0,0);
				transition: $ease-linear;
				width: 100%;
				&:hover {
					box-shadow: 4px 4px 8px rgba(0,0,0,0.2);
				}
				&:focus {
					box-shadow: 4px 4px 8px rgba(0,0,0,0.4);
					outline: none;
				}


				&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
				  color: $color-form-placeholder;
				}
				&::-moz-placeholder { /* Firefox 19+ */
				  color: $color-form-placeholder;
				}
				&:-ms-input-placeholder { /* IE 10+ */
				  color: $color-form-placeholder;
				}
				&:-moz-placeholder { /* Firefox 18- */
				  color: $color-form-placeholder;
				}
			}
			
			&.ginput_container_select {
				position: relative;
				&::before, 
				&::after {
					content: '';
					position: absolute;
					display: block;
					background: #fff;
					pointer-events: none;
				}
				&::before {
					top: 10px;
					right: 2px;
					width: 30px;
					height: 30px;
				} 
				&::after {
					width: 11px;
					height: 11px;
					right: 15px;
					top: 17px;
					background: #fff;
					border: 2px solid $color-form-bg;
					border-width: 0 2px 2px 0;
					transform: rotate(45deg);
				}
			}
			
			&.ginput_container_checkbox {
				li {
					input {
						border: 1px solid #fff;
						background:  $color-form-bg;
						height: 16px;
						margin: 0;
						box-shadow: 0 0 0 rgba(0,0,0,0);
						transition: $ease-linear;

						&:hover {
							box-shadow: 2px 2px 8px rgba(0,0,0,0.2);
						}
						&:focus {
							box-shadow: 2px 2px 8px rgba(0,0,0,0.4);
							outline: none;
						}
					}
					label { 
						font-size: em(18px); 
						padding-left: 10px;
					}
				}
			}
			
		}
		
		&.gfield_error {
			background: transparent;
			border: none;
			margin-top: 16px !important;
			padding-top: 0;
			padding-bottom: 0;
			
			&::after {
				font-family: "cleaner-suburbs" !important;
				content: "\69";
				color: #fff;
				position: absolute;
				top: 42px;
				right: -30px;
			}
			
			&.ginput_container_checkbox::after {
				top: 0;
			}
			
			.gfield_label {
				color: #fff;
				margin-top: 0 !important;
			}
			
			.ginput_container {
				margin-top: 0 !important;
				
				select,
				input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']),
				textarea {
					border-color: $color-form-error;
					color: $color-form-error;
					&::-webkit-input-placeholder { color: $color-form-error; }
					&::-moz-placeholder { color: $color-form-error; }
					&:-ms-input-placeholder { color: $color-form-error; }
					&:-moz-placeholder { color: $color-form-error; }
				}
			}
			
			ul.gfield_checkbox {
				color: #fff;
				input {
					border-color: $color-form-error;
				}
			}
			
			
			
			&.ginput_container_checkbox .validation_message {
				display: block;
			}
			
			@include breakpoint-lt('sm') {
				&::after {
					display: none;
				}
			}
			
		}
		
		.validation_message {
				/*
				position: absolute;
				top: 0;
				right: 0;
				padding: 0 !important;
				margin: 0;
				color: $color-form-error;
				width: auto;
				@include font-avenir-medium();
				font-size: em(16px);
				*/
				display: none;
			}
		
	}
	
	.gform_page_footer {
		position: relative;
		border-top: 1px solid lighten($color-form-bg, 20%);
		text-align: right;
		margin-top: 36px;
		padding-top: 36px;
		
		.gform_ajax_spinner {
			position: absolute;
			right: 14px;
			top: 50px;
		}
		
		.button {
			margin-right: 0 !important;
			margin-left: 10px;
			padding: 0 40px;
		}
	}
	
	.button {
		color: $color-text-green-lite;
		background: #fff;
		box-shadow: 0 0 0 rgba(0,0,0,0);
		transition: $ease-linear;

		&:hover,
		&:focus {
			color: $color-text-green;
			background: #fff;
			box-shadow: 4px 4px 8px rgba(0,0,0,0.4);
			outline: none;
		}
	}
	

	
}

.gform_confirmation_message {
	font-size: em(24px);
}

@media only screen and (max-width: 641px) {
	.gform_wrapper .gform_page_footer input:not([type='radio']):not([type='checkbox']):not([type='image']):not([type='file']) {
		line-height: em(46px);
	}
}