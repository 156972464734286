/* Banner */

.banner {
	position: relative;
	height: 435px;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	
	&::after {
		content:'';
		background: $gradient-transparent;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
	}
	
	.banner-content {
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		transform: translateY(-50%);
		z-index: 2;
		
		color: #fff;
		text-align: center;
		
		.logo-ihb {
			img {
				height: 72px;
			}
		}
		
		h1,h2 {
			@include font-avenir-heavy();
			text-shadow: 1px 0 16px rgba(23, 31, 34, 0.6);
		}
		
		h2 {
			font-size: 32px;
			line-height: (32 / 20);
			margin: 20px 0 0 0;
		}
		
		h1 {
			font-size: 40px;
			line-height: (70 / 50);
			margin: 10px 0 0 0;
		}
		
		@include breakpoint-lt('sm') {
			h2 {
				font-size: 18px;
			}
			h1 {
				font-size: 33px;
			}
		}
		
	}
	
	.caption {
		position: absolute;
		right: 20px;
		bottom: 8px;
		font-size: em(14px);
		color: #fff;
		line-height: 1;
		font-style: italic;
		text-shadow: 1px 1px 0 rgba(23, 31, 34, 0.6);
		z-index: 2;
	}
	
	&.banner-home {
		height: 594px;
		.banner-content {
			top: 275px;
			transform: translateY(0);
		}
		
		@include breakpoint-lt('sm') {
			min-height: 320px;
			height: auto;
			background-position: center top;
			background-size: auto 320px;
			&::after {
				bottom: auto;
				height: 320px;
			}
			.banner-content {
				position: static;
				transform: none;
				text-align: center;
				padding: 340px 20px 60px 20px;
				h1 {
					font-size: 20px;
					line-height: (32 / 20);
					color: $color-text-green;
					text-shadow: none;
				}
				h2 {
					display: none;
				}
			}
			.caption {
				bottom: '';
				top: 300px; 
			}
		}
		
		@include breakpoint-lt('xs') {
			min-height: 220px;
			background-size: auto 220px;
			&::after {
				height: 220px;
			}
			.banner-content {
				padding-top: 240px;
			}
			.caption {
				top: 200px; 
			}
		}
	}
	
	&.banner-simple {
		height: 200px;
		background: $gradient;
	}
	
}

