/* CSS Document */

section {
	padding-top: 96px;
	padding-bottom: 96px;
	
	@include breakpoint-lt('sm') {
		padding-top: 48px;
		padding-bottom: 48px;
	}
	
}