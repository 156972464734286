.eligibility-list {

	
	.eligibility {
		text-align: center;
		
		.eligibility-icon {
			color: $color-text-green-lite;
			font-size: em(60px);
			line-height: 1;
		}
		.eligibility-head {
			h3 {
				@include font-avenir-book();
				font-size: em(21px);
				line-height: (32 / 21);
			}
		}
		.eligibility-body {
			padding-bottom: 80px;
			
			.prize {
				@include font-avenir-heavy();
				color: $color-text-green;
			}
		}
		
	}
	
}
