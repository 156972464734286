/* CSS Document */

.page-template-template-eligibility {
	
	.eligibility-leadin {
		text-align: center;
		position: relative;
		padding-top: 60px;
    	padding-bottom: 70px;
		
		&::after {
			content: '';
			position: absolute;
			top: 36px;
			left: 20px;
			right: 20px;
			bottom: 10px;
			z-index: -1;
			opacity: 0.1;
			background: url('../images/csa_logo.svg') center center no-repeat;
			background-size: contain;
			display: none;
		}
		
		.container {
			max-width: 720px;
		}
		
		h2 {
			@include font-avenir-book();
			font-size: em(30px);
			line-height: (41 / 30);
		}
		
		@include breakpoint-lt('sm') {
			.container {
				padding-left: $grid-padding-sm;
				padding-right: $grid-padding-sm;
			}
		}
	}
	
	
	
}