footer {
	min-height: 83px;
	line-height: 82px;
	border-top: 1px solid $color-rule-green;
	color: $color-text-footer;
	@include clearfix;
	
	.footer-left {
		float: left;
	}
	.footer-right {
		float: right;
	}
	
	a {
		color: $color-text-footer;
		text-decoration: none;
		&:hover {
			color: $color-text-link;
		}
	}
	
	@include breakpoint-lt('sm') {
		padding: 0 20px;
	}
	
	@include breakpoint-lt('xs') {
		line-height: 1.5;
		padding: 6px 0;
		.footer-left,
		.footer-right {
			float: none;
			padding: 6px 20px;
			text-align: center;
		}
	}
}