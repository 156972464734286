/* Simple page template */

.page-template-template-vote {
	
	.content {
		position: relative;
		padding-top: 60px;
		
		&::after {
			content: '';
			position: absolute;
			top: 100px;
			left: 20px;
			right: 20px;
			bottom: 70px;
			z-index: -1;
			opacity: 0.1;
			background: url('../images/csa_logo.svg') center center no-repeat;
			background-size: contain;
		}
		
		.container {
			max-width: 800px;
		}
		
		h1 {
			line-height: 1.4;
		}
		
		@include breakpoint-lt('sm') {
			padding-top: 24px;
		}
		
	}
	
}