/* Homepage specific styles */

.page-template-template-homepage {
	
	.introduction {
		.span-4 {
			text-align: center;
		}
		.span-8 {
			h2 {
				font-size: em(28px);
				line-height: (28 / 21);
				margin-top: 0;
				margin-bottom: em(20px);
			}
			.button {
				margin-top: em(20px);
			}
		}
		
		@include breakpoint-lt('sm') {
			text-align: center;
			margin-top: 120px;
			padding-top: 140px;
			background: $color-text-green-lite;
			.span-4 {
				position: absolute;
				width: 280px;
				height: 280px;
				top: -280px;
				left: 50%;
				margin-left: -140px;
				border-radius: 50%;
				background-color: $color-bg-green;
				box-shadow: 4px 4px 16px rgba(0,0,0,0.2);
				overflow: hidden;
				
				img { 
					max-width: 180px; 
					max-height: 180px; 
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}
			.span-8 {
				color: #fff;
				padding-top: em(24px);
				
				h2 {
					font-size: em(21px);
				}
				.button {
					border: 2px solid #fff;
					background: $color-text-green-lite; 
				}
			}
		}
	}
	
	.key-dates {
		padding-bottom: 60px;
		.span-4 {
			h2, h3 {
				color: $color-text-green;
				margin-top: 0;
			}
			h2 {
				@include font-avenir-heavy();
				font-size: em(38px);
				line-height: (58 / 38);
			}
		}

		@include breakpoint-lt('md') {
			.span-4, 
			.span-8 {
				width: 100%;
				float: none;
			}

			.span-4 {
				text-align: center;
				img {
					display: none;
				}
			}
		}
		
		.span-12 {
			text-align: center;
			padding-top: 40px;
			.green {
				@include font-avenir-heavy();
			}
			a {
				color: $color-text-body;
			}
		}
		
		
	}
	
}