@import '../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/normalize';
@import '../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/clearfix';
@import '../vendor/sass-breakpoints-px/index';
@import '../vendor/fontastic/style';


// abstracts
@import './abstracts/variables';
@import './abstracts/colors';
@import './abstracts/fonts';
@import './abstracts/box-sizing';

// base
@import './base/typography';
@import './base/wordpress';

// components
@import './components/banner';
@import './components/background';
@import './components/button';
@import './components/left-right-blocks';
@import './components/date-table';
@import './components/awards-list';
@import './components/eligibility-list';
@import './components/nominees-list';
@import './components/cta';
@import './components/nomination-form';

// layout
@import './layout/header';
@import './layout/main-content';
@import './layout/grid';
@import './layout/section';
@import './layout/footer';
@import './layout/mobile';

// pages
@import './pages/homepage';
@import './pages/awards';
@import './pages/eligibility';
@import './pages/vote';
@import './pages/nominate';
@import './pages/simple';

// themes

// vendors

img {
    max-width: 100%;
    height: auto;
}

// .gform_fields {
//     .gfield {
//         padding-right: 0 !important;

//         &.nominating-click {
//             border-top: 1px solid #FFF;
//             border-bottom: 1px solid #FFF;

//             &.clicked-active {
//                 border-bottom: 0px solid #FFF;
//             }

//             .gfield-choice-input {
//                 visibility: hidden;
//                 opacity: 0;
//                 height: 0 !important;
//                 width: 0;
//                 display: block;
//             }

//             .gform-field-label {
//                 padding: 0 !important;
//                 font-family: Avenir LT W01_85 Heavy1475544, Arial, Helvetica, "sans-serif" !important;
//                 font-size: 20px !important;
//                 position: relative;
//                 width: 100%;

//                 &.toggle-active {
//                     &::after {
//                         transform: rotate(90deg);
//                     }
//                 }

//                 &::before {
//                     content: '';
//                     top: 15px;
//                     right: 0;
//                     width: 20px;
//                     height: 2px;
//                     position: absolute;
//                     background-color: #FFF;
//                 }

//                 &::after {
//                     content: '';
//                     top: 6px;
//                     right: 9px;
//                     width: 2px;
//                     height: 20px;
//                     position: absolute;
//                     background-color: #FFF;
//                     transition: all 0.3s ease-in-out;
//                 }
//             }

//             ul {
//                 width: 100% !important;
//                 margin: 20px 0;

//                 li {
//                     width: 100%;

//                     label {
//                         max-width: 100% !important;

//                         span {
//                             text-decoration: underline;
//                         }
//                     }

//                 }
//             }
//         }
//     }
// }

// .end-toggle {
//     border-bottom: 1px solid #FFF;
//     padding-bottom: 25px !important;
// }

#shadow-host-companion{
    padding: 0 !important; 
}