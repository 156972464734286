/* CSS Document */

.date-table {
	
	td {
		padding: 24px 30px;
		background-color: #fff;
		border-bottom: 4px solid $color-bg-green;
		
		&.date {
			background-color: #d5e7f2;
			text-align: center;
			border-right: 4px solid $color-bg-green;
			@include font-avenir-medium();
			min-width: 175px;
		}
	}
	
	@include breakpoint-lt('sm') {
		td { 
			padding: 20px;
			&.date {
				min-width: 100px;
			}
		}
	}
	
	@include breakpoint-lt('xs') {
		td { 
			display: block;
			&.date {
				position: relative;
				min-width: 0;
				border: none;
				text-align: left;
				&::after {
					content:'';
					position: absolute;
					width: 20px;
					height: 20px;
					left: 44px;
					bottom: -10px;
					background-color: #d5e7f2;
					transform: rotate(45deg);
				}
			}
		}
	}
	
}