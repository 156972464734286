/* CSS Document */

body {
	@include font-avenir-book();
	font-size: $base-font-size;
}

h1, h2, h3 {
	@include font-avenir-medium();
}

b, strong {
	@include font-avenir-heavy();
}

a {
	color: $color-text-link;
}

.green {
	color: $color-text-green-lite;
}

.large {
	font-size: em(24px);
}