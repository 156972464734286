/**
 * grid
 */

$screen-xs:                  480px;
$screen-sm:                  768px;
$screen-md:                  992px;
$screen-lg:                  1200px;

/**
 * responsive
 */

$breakpoints: (
	'xs':	$screen-xs,
	'sm':	$screen-sm,
	'md':	$screen-md,
	'lg':	$screen-lg
);

$width-container-lg: 1140px;
$width-container-md: 960px;
$width-container-sm: 730px;

/*
 * Grid 
 */
$grid-padding-lg: 35px;
$grid-padding-sm: 20px;

/**
 * easing
 */

$ease-linear:               0.3s linear;


/**
 * Sizes
 */

$height-header: 84px; 