/* CSS Document */

.page-template-template-nominate {
	
	.nominate-leadin {
		text-align: center;
		position: relative;
		padding-top: 60px;
    	padding-bottom: 70px;

		.container {
			max-width: 870px;
		}
		
		h2 {
			@include font-avenir-book();
			font-size: em(30px);
			line-height: (41 / 30);
			margin-bottom: em(36px);
			
			.green {
				@include font-avenir-heavy();
				color: $color-text-green;
			}
		}
		
		@include breakpoint-lt('sm') {
			.container {
				padding-left: $grid-padding-sm;
				padding-right: $grid-padding-sm;
			}
		}
	}
	
	.nominate-form-section {
		background: $color-form-bg;
		color: #fff;
		text-align: center;
		padding-top: 60px;
    	padding-bottom: 60px;
		
		.container {
			max-width: 480px;
		}
		
		h2 {
			@include font-avenir-book();
			font-size: em(30px);
			line-height: (41 / 30);
		}
		
		@include breakpoint-lt('sm') {
			.container {
				padding-left: $grid-padding-sm;
				padding-right: $grid-padding-sm;
			}
		}
		
	}
	
}