/* CSS Document */

body {
	position: relative;
	padding-top: $height-header;

	@include breakpoint-lte('sm') {
		padding-top: 0;
	}
}

header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: $height-header;
	background: #fff;
	color: $color-text-header;
	border-bottom: 1px solid $color-rule-grey;
	z-index: 5;

	.logged-in & {
		top: 32px;
		@media (max-width: 782px){
			top: 46px;
		}
	}

	.logos {
		float: left;

		.logo-bcc {
			position: relative;
			float: left;
			height: 84px;
			margin-top: ($height-header - 84) / 2;

			img {
				height: 80px;
				padding-top: 2px;
				padding-left: 34px;
				padding-right: 44px;
			}

			&::before,
			&::after {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				height: 42px;
				width: 9px;
				background: $color-bcc-yellow;
				border: 14px solid $color-bcc-blue;
				border-width: 14px 0;

			}

			&::after {
				top: 42px;
				background: $color-bcc-blue;
				border-color: $color-bcc-yellow;
			}
		}

		.logo-bcc-sm,
		.menu-close {
			display: none;
		}

		.logo-cs {
			float: left;
			position: relative;
			margin-top: 8px;

			img {
				height: 73px;
				width: 240px;
				padding-top: 10px;
			}

			&::before {
				content: 'In partnership with';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				text-align: center;
				@include font-avenir-heavy();
				font-size: em(12px);
			}
		}

	}

	nav {
		float: right;
		margin-top: 26px;
		margin-right: 36px;

		a {
			display: inline-block;
			padding: 0 24px;
			line-height: 46px;
			text-decoration: none;
			@include font-avenir-heavy();
			color: $color-text-header;

			&:hover {
				color: $color-text-header-hover;
			}
		}

		a:last-of-type {
			color: #fff;
			background: $color-logo-green-bright;
			padding: 0 27px;
			border-radius: 23px;
			margin-left: 18px;
			transition: all $ease-linear;

			&:hover {
				color: #fff;
				background: $color-logo-green-mid2;
			}
		}

		.menu-close {
			display: none;
		}
	}

	.menu-toggle,
	.menu-shadow {
		display: none;
	}

	@include breakpoint-lt('lg') {
		nav a {
			padding: 0 16px;

			&:last-of-type {
				margin-left: 10px;
			}
		}
	}

	@include breakpoint-lt('md') {
		.logos {

			margin-top: 10px;
			.logo-bcc {
				display: none;
			}

			.logo-bcc-sm {
				display: block;
				position: relative;
				float: left;
				height: 48px;
				margin-top: ($height-header - 48) / 2;
				padding: 0 20px 0 30px;
				border-right: 1px solid $color-rule-grey;

				img {
					height: 48px;
					vertical-align: middle;
				}

				.title {
					height: 36px;
					padding-left: 10px;
				}
			}

			.logo-cs {
				height: 46px;
				margin-top: ($height-header - 46) / 2;
				padding-left: 20px;

				img {
					height: 46px;
					padding-top: 0;
				}

				&::before {
					display: none;
				}
			}
		}

	}

	@media (max-width: em(930px - 1px, 16px)) {
		nav {
			margin-right: 20px;

			a {
				padding: 0 10px;

				&:last-of-type {
					margin-left: 8px;
				}
			}
		}
	}

	@include breakpoint-lte('sm') {
		position: relative;
		height: 90px;

		.logos {
			.logo-bcc-sm {
				padding: 0 0 0 20px;
				height: 41px;
				margin-top: 16px;
				border-right: none;

				img {
					height: 41px;
					vertical-align: middle;
				}

				.title {
					height: 32px;
					padding-left: 7px;
				}
			}

			.logo-cs {
				height: 36px;
				margin-top: 16px;
				padding-left: 20px;
				clear: left;

				img {
					height: 36px;
					padding-top: 0;
				}
			}
		}

		.menu-shadow {
			display: block;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: 0;
			background: rgba(0, 0, 0, 0.8);
			visibility: hidden;
			opacity: 0;
			transition: visibility 0s $ease-linear, opacity $ease-linear;
		}

		nav {
			position: fixed;
			display: block;
			top: 0;
			right: -480px;
			bottom: 0;
			width: 480px;
			padding-right: 96px;
			background: #fff;
			transition: $ease-linear;
			z-index: 1;
			margin: 0;
			padding-top: 96px;

			a,
			a:last-of-type {
				display: block;
				border-top: 1px solid $color-rule-grey;
				@include font-avenir-book();
				font-size: em(36px);
				text-transform: uppercase;
				padding: 32px 54px;
				line-height: (45 / 36);
				color: $color-text-green-lite;

				border-radius: 0;
				//background: transparent;
				margin: 0;

				&.current_page_item {
					color: #cacaca;
				}

				&:hover {
					background: transparent;
				}
			}

			a:last-of-type {
				color: #fff;

				&.current_page_item {
					color: #fff;
				}
			}

			.menu-close {
				position: absolute;
				display: block;
				top: 0;
				width: 96px;
				right: 480px - 96px;
				height: 97px;
				background: $color-text-green-lite;
				color: #fff;
				transition: $ease-linear;
				cursor: pointer;
				border: 1px solid $color-rule-grey;
				z-index: 2;

				&::before,
				&::after {
					content: '';
					position: absolute;
					display: block;
					width: 48px;
					height: 3px;
					background: #fff;
					top: 43px;
					left: 24px;
				}

				&::before {
					transform: rotate(45deg);
				}

				&::after {
					transform: rotate(-45deg);
				}

			}
		}

		.menu-toggle {
			display: block;
			position: absolute;
			width: 48px;
			height: 48px;
			top: 20px;
			right: 20px;
			cursor: pointer;

			&::before,
			&::after {
				content: '';
				position: absolute;
				display: block;
				width: 30px;
				left: 9px;
			}

			&::before {
				top: 12px;
				height: 23px;
				border: 3px solid $color-text-green-lite;
				border-width: 3px 0;
			}

			&::after {
				top: 22px;
				height: 3px;
				background: $color-text-green-lite;
			}

		}

	}

	@include breakpoint-lt('xs') {
		nav {
			width: 300px;
			padding-right: 48px;
			padding-top: 48px;

			a,
			a:last-of-type {
				font-size: em(24px);
				padding: 20px 24px;
			}

			.menu-close {
				width: 48px;
				right: 300px - 48px;
				height: 49px;

				&::before,
				&::after {
					width: 30px;
					top: 22px;
					left: 8px;
				}
			}
		}
	}


}

body.mobile-menu-open {
	header {
		.menu-shadow {
			visibility: visible;
			opacity: 1;
			transition: visibility 0s, opacity $ease-linear;
		}

		nav {
			right: 0;

			.menu-close {
				right: 0;
			}

			@include breakpoint-lt('xs') {
				padding-right: 48px;
			}
		}
	}
}