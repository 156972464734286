.awards-list {
	
	border-bottom: 1px solid $color-rule-grey;
	
	.award {
		.award-head {
			padding-top: 50px;
			h3 {
				font-size: em(21px);
				line-height: (30 / 21);
				margin-top: 0;
			}
		}
		.award-body {
			
			.prize {
				@include font-avenir-heavy();
				color: $color-text-green-lite;
			}
		}
		.award-foot {
			padding-top: 10px;
			padding-bottom: 50px;
		}

		&:nth-child(2n) {
			background: $color-bg-grey;
		}
		
	}
	
	
	
}
