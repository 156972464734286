/* Awards template specific styles */

.page-template-template-awards {
	
	.awards-content {
		h2 {
			font-size: em(28px);
		}
	}
	
}

