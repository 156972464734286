//browsers always use 16px regardless of the font-size set on the html or body elements - see http://www.filamentgroup.com/lab/how-we-learned-to-leave-body-font-size-alone.html

/// The base px size
/// @type {px}
$base-font-size: 16px !default;

/// Convert a px value to em
/// @param {px} $val - The desired px size
/// @param {px} $ctx [$base-font-size] - The base px size
/// @return {em} The px value in em
@function em($val, $ctx: $base-font-size) {

  //make sure the divider is not 0
  @if $ctx == 0 {
    @error 'sass-ems: Context cannot be `0`.';
  }

  //return a unit less 0 (best practice)
  @if $val == 0 {
    @return 0
  }

  //make sure units are in px
  @if (unit($val) != 'px' or unit($val) != 'px') {
    @error 'sass-ems: Units must be `px`.';
  }

  @return $val;
  @return ($val / $ctx) * 1em;
}

$breakpoints: (
  "xs": 0px,    //targeting <568px devices (e.g. all iPhones <6)
  "sm": 568px,  //targeting >=568px devices (e.g. iPhones >=6)
  "md": 768px,  //targeting >=768px tablets (e.g. portrait iPad)
  "lg": 1004px  //targeting >=1024px tablets (e.g. landscape iPad) and desktops but leaving room for the scroll bar
) !default;

@mixin breakpoint-lt($to) {
  @media (max-width: em(map-get($breakpoints, $to) - 1px, 16px)) {
	@content
  }
}

@mixin breakpoint-lte($to) {
  @media (max-width: em(map-get($breakpoints, $to), 16px)) {
    @content
  }
}

@mixin breakpoint-gt($from) {
  @media (min-width: em(map-get($breakpoints, $from) + 1, 16px)) {
	@content
  }
}

@mixin breakpoint-gte($from) {
  @media (min-width: em(map-get($breakpoints, $from), 16px)) {
    @content
  }
}

@mixin breakpoint-between($from, $to) {
  @media (min-width: em(map-get($breakpoints, $from) + 1, 16px)) and (max-width: em(map-get($breakpoints, $to) - 1, 16px)) {
	@content
  }
}

@mixin breakpoint($from, $to: null) {

  @if ($from and $to) {

    @media (min-width: em(map-get($breakpoints, $from), 16px)) and (max-width: em(map-get($breakpoints, $to) - 1, 16px)) {
      @content
    }

  } @else if ($from) {

    @include breakpoint-gte($from) {
      @content
    }

  } @else {
    @content
  }

}
