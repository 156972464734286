.cta {
	background: $color-bg-green;
	border-top: 1px solid $color-rule-green;
	font-size: em(30px);
	line-height: 1.5;
	padding-top: 80px;
	padding-bottom: 80px;
	
	.green {
		@include font-avenir-heavy();
		color: $color-text-green;
	}
	
	.cta-left { 
		float: left;
		width: 150px;
		img {
			width: 150px;
		}
	}
	.cta-right {
		margin-left: 210px;
		p {
			margin: 0;
		}
		.subtext {
			margin-top: 40px;
			font-size: em(20px);
			a {
				color: $color-text-body;
			}
		}
	}
	
	@include breakpoint-lt('sm') {
		font-size: em(20px);
		.cta-left {
			width: 100%;
			float: none;
			text-align: center;
			img {
				width: 120px;
			}
		} 
		.cta-right {
			margin-left: 0;
			padding: 0 $grid-padding-sm;
			text-align: center;
			.subtext {
				margin-top: 20px;
				font-size: em(16px);
			}
		}
	}
}
